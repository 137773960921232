export const strict = false

export const state = () => ({
  user: null,
  error: '',
  minimizeVideo: null,
  ratingType: '',
  enterData: {
    notifications: 0,
    top_anime: [],
    top_comics: [],
    top_ranobes: [],
    nf: []
  },
  setShowAuthForm: false,
  feedback: false,
  isDesktop: false,
  whisperStore: null,
  inviteToStream: null,
  themeDark: '',
  showAdsGlobal: true,
  tabActive: 1
})

export const mutations = {
  setTabActive(state, payload) {
    state.tabActive = payload
  },
  setShowAdsGlobal(state, payload) {
    state.showAdsGlobal = payload
  },
  setDarkTheme(state, payload) {
    state.themeDark = payload
  },
  setInviteToStream(state, payload) {
    state.inviteToStream = payload
  },
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setShowAuthForm(state, payload) {
    state.setShowAuthForm = payload
    setTimeout(() => {
      state.setShowAuthForm = false
    }, 2000)
  },
  minimizeVideo(state, payload) {
    state.minimizeVideo = payload
  },
  setWhisper(state, payload) {
    state.whisperStore = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    if (typeof payload === 'string') {
      payload = {
        status: 'info',
        msg: payload
      }
    }
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  },
  setRatingType(state, payload) {
    state.ratingType = payload
  },
  setEnterData(state, payload) {
    state.enterData = Object.freeze(payload)
  },
  toggleFeedback(state, payload) {
    state.feedback = payload
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)
      commit('setDevice', app.$device)
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
